import React from "react";
const AboutPage = () => {
  return (
    <div className="bg-white overflow-hidden">
      <div className="relative max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
        <div className="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen" />
        <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
          <div>
            <h2 className="text-base text-red-600 font-semibold tracking-wide uppercase">
              ABOUT
            </h2>
            <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-800 sm:text-4xl">
              ADVOCATES FOR DIGNITY
            </h3>
          </div>
        </div>
        <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative lg:row-start-1 lg:col-start-2">
            <svg
              className="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"
              />
            </svg>
            <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
              <figure>
                <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                  <img
                    className=" object-cover object-center"
                    src={require("../../assets/images/logo.png").default}
                    alt="Logo"
                  />
                </div>
              </figure>
            </div>
          </div>
          <div className="mt-8 lg:mt-0">
            <div className="booker  prose prose-indigo text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
              <p>
                Advocates For Dignity (AFD) is an advocacy organization that
                seeks the rule of law, democracy and fundamental human rights
                and liberties with a special focus on Turkey and its citizens
                abroad. A country, with eighty million citizens, that is
                experiencing a dramatic decline in its parliamentary democracy
                under its autocratic leadership.
                <br />
                <br />
                AFD is a non-profit organization set up by a group of Turkish
                speaking Australian citizens, who wanted to make wider
                Australian community aware, of the victims of Turkey’s unlawful
                crackdown on its citizens and the cessation of press freedom in
                Turkey, where 191 journalists have been jailed, and close to 200
                media outlets have been terminated by a series of arbitrary
                decisions taken by the Turkish authorities. It is based in
                Sydney, Australia, a country that is a strong advocate of
                freedom of expression.
                <br />
                <br />
                AFD is committed to being a reference source which provides a
                broad and accurate perspective on victims of Turkey, monitoring
                daily developments through the lens of fact-based investigative
                research, and documenting individual cases of violation of
                fundamental rights and liberties.
                <br />
                <br />
                The founders of AFD are professionals of various backgrounds who
                have been following the tragic deterioration of democracy in
                Turkey. They have the expertise, human resources, and network
                around the world to track events in Turkey despite serious
                challenges and shortfalls to accessing information.
                <br />
                <br />
                AFD is set to expand its area of interests in the future by
                adding more countries to the list where Turkish government has
                or is trying to oppress its own citizens abroad. It hopes to
                make an impact by naming and shaming those who are responsible
                for violating the rights of victims, providing a reliable source
                of information on Turkey, and exposing an increasingly
                oppressive regime in Turkey.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
