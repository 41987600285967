import { Empty } from "antd";
import React, { useEffect, useState } from "react";
import { AdminService } from "../../resources/AdminService";
import PublicationItem from "./PublicationItem";

const PublicationsPage = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [publications, setPublications] = useState(null);

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getPublications().then((data) => {
        setPublications(data.data);
      });
    }
  }, [initialRender]);
  return (
    <div>
      {publications && publications.length > 0 ? (
        <>
          {publications.map((item) => (
            <div key={item.key}>
              <PublicationItem item={item.p} />
            </div>
          ))}
        </>
      ) : (
        <Empty />
      )}
      {!publications && <h3>Loading...</h3>}
      <br />
    </div>
  );
};

export default PublicationsPage;
