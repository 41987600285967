import React from "react";
import { Timeline } from "react-twitter-widgets";

const TwitterWidget = () => {
  return (
    <div>
      <Timeline
        dataSource={{ sourceType: "profile", screenName: "AFD_AUSTRALIA" }}
        options={{ height: 600, id: "profile:AFD_AUSTRALIA" }}
      />
    </div>
  );
};

export default TwitterWidget;
