import { Spin } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React from "react";
import SliderBar from "../admin/banner/SliderBar";
import AboutBar from "./AboutBar";
import EventBanner from "./EventBanner";
import EventsBar from "./EventsBar";
import NewsBar from "./NewsBar";
import PublicationItem from "./PublicationItem";
import SocialBanner from "./SocialBanner";

const HomePage = ({ layout }) => {
  const { sm } = useBreakpoint();
  return (
    <div>
      <Spin spinning={layout === null}>
        {layout && layout.slides.length > 0 && sm && (
          <SliderBar slides={layout.slides} />
        )}
        {layout && layout.events.isThere && (
          <EventBanner item={layout.events.data} />
        )}
        {layout && layout.publication.isThere && (
          <PublicationItem item={layout.publication.data} />
        )}
      </Spin>
      <br />
      <NewsBar />

      <EventsBar />
      <AboutBar />
      <SocialBanner />
    </div>
  );
};

export default HomePage;
