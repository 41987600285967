import { Row, Col, Card, Statistic } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AdminService } from "../../resources/AdminService";

const AdminPage = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [reports, setReports] = useState(null);
  const router = useHistory();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getDashboard().then((data) => {
        setReports(data.data);
      });
    }
  }, [initialRender]);
  return (
    <div>
      {reports && (
        <div
          style={{
            backgroundColor: "#f5f5f5",
            padding: "15px",
            border: "1px solid #ddd",
          }}
        >
          <p className="text-red-600 text-3xl font-extrabold">Dashboard</p>
          <Row gutter={[6, 6]} wrap>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Card
                className="stat-card"
                hoverable
                onClick={() => router.push("/admin/events")}
              >
                <Statistic
                  title="
        Events"
                  value={reports.events}
                  valueStyle={{ fontWeight: "bold" }}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Card
                className="stat-card"
                hoverable
                onClick={() => router.push("/admin/events")}
              >
                <Statistic
                  title="
        Upcoming"
                  value={reports.upcoming}
                  valueStyle={{ fontWeight: "bold" }}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Card
                className="stat-card"
                hoverable
                onClick={() => router.push("/admin/events")}
              >
                <Statistic
                  title="
        Past"
                  value={reports.events - reports.upcoming}
                  valueStyle={{ fontWeight: "bold" }}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Card
                className="stat-card"
                hoverable
                onClick={() => router.push("/admin/publications")}
              >
                <Statistic
                  title="
        Publications"
                  value={reports.pubs}
                  valueStyle={{ fontWeight: "bold" }}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Card
                className="stat-card"
                hoverable
                onClick={() => router.push("/admin/news")}
              >
                <Statistic
                  title="
        News"
                  value={reports.news}
                  valueStyle={{ fontWeight: "bold" }}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Card
                className="stat-card"
                hoverable
                onClick={() => router.push("/admin/contacts")}
              >
                <Statistic
                  title="
        Contacts"
                  value={reports.contacts}
                  valueStyle={{ fontWeight: "bold" }}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Card
                className="stat-card"
                hoverable
                onClick={() => router.push("/admin/contacts")}
              >
                <Statistic
                  title="Unread"
                  value={reports.unread}
                  valueStyle={{
                    fontWeight: "bold",
                    color: reports.unread === 0 ? "green" : "coral",
                  }}
                />
              </Card>
            </Col>

            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Card
                className="stat-card"
                hoverable
                onClick={() => router.push("/admin/subscriptions")}
              >
                <Statistic
                  title="Subsriptions"
                  value={reports.subs}
                  valueStyle={{ fontWeight: "bold" }}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Card
                className="stat-card"
                hoverable
                onClick={() => router.push("/admin/registrations")}
              >
                <Statistic
                  title="Registrations"
                  value={reports.regs}
                  valueStyle={{ fontWeight: "bold" }}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Card className="stat-card" hoverable>
                <Statistic
                  title="
        Images"
                  value={reports.images}
                  valueStyle={{ fontWeight: "bold" }}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Card className="stat-card" hoverable>
                <Statistic
                  title="Videos"
                  value={reports.videos}
                  valueStyle={{ fontWeight: "bold" }}
                />
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default AdminPage;
