import { Button, Form, Input, notification } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useState } from "react";
import { AdminService } from "../../resources/AdminService";

const AboutBar = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { lg } = useBreakpoint();
  const onFinish = (values) => {
    setLoading(true);
    AdminService.newSubscription(values)
      .then((data) => {
        notification.success({
          message: "Thank you.",
          description: "Subscription completed successfully!",
        });
        setLoading(false);
        form.resetFields();
      })
      .catch((data) => {
        setLoading(false);
      });
  };
  return (
    <div>
      <div className="bg-white py-8">
        <div className="relative sm:py-16">
          <div aria-hidden="true" className="hidden sm:block">
            <div className="absolute inset-y-0 left-0 w-1/2 bg-white rounded-r-3xl" />
            <svg
              className="absolute top-8 left-1/2 -ml-3"
              width={404}
              height={392}
              fill="none"
              viewBox="0 0 404 392"
            >
              <defs>
                <pattern
                  id="8228f071-bcee-4ec8-905a-2a059a2cc4fb"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={392}
                fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)"
              />
            </svg>
          </div>
          <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-6xl lg:px-8">
            <div className="relative rounded-2xl px-6 py-10 border  bg-gradient-to-b from-red-50 to-red-200  overflow-hidden shadow-xl sm:px-12 sm:py-20">
              <div
                aria-hidden="true"
                className="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0"
              ></div>
              <div className="relative">
                <div className="sm:text-center">
                  <h2 className="text-3xl font-extrabold text-red-700  sm:text-4xl">
                    Get notified our news & events
                  </h2>
                  <p className="mt-6 mx-auto max-w-3xl text-lg text-gray-800">
                    Advocates For Dignity (AFD) is an advocacy organization that
                    seeks the rule of law, democracy and fundamental human
                    rights and liberties with a special focus on Turkey and its
                    citizens abroad. A country, with eighty million citizens,
                    that is experiencing a dramatic decline in its parliamentary
                    democracy under its autocratic leadership.
                  </p>
                </div>
                <Form
                  onFinish={onFinish}
                  form={form}
                  layout={lg ? "inline" : "vertical"}
                  className="justify-center"
                >
                  <Form.Item
                    name="name"
                    rules={[{ required: true, message: "Name required" }]}
                  >
                    <Input placeholder="Type your name" size="large" />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    rules={[{ required: true, message: "Email required" }]}
                  >
                    <Input
                      placeholder="Type your email"
                      type="email"
                      autoComplete="email"
                      size="large"
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      loading={loading}
                      type="primary"
                      htmlType="submit"
                      size="large"
                    >
                      Notify
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutBar;
