import {
  SearchOutlined,
  DeleteOutlined,
  CloseOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  notification,
  Space,
  Tooltip,
  Button,
  Popconfirm,
  Switch,
  PageHeader,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AdminService } from "../../../resources/AdminService";
import NewPublicationForm from "./NewPublicationForm";

const Publication = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [publications, setPublications] = useState(null);
  const [newPanel, setNewPanel] = useState(false);
  const router = useHistory();

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getPublications().then((data) => {
        setPublications(data.data);
      });
    }
  }, [initialRender]);

  const activeChanged = (a) => {
    AdminService.changePubsActive(a).then((data) => {
      setInitialRender(true);
      notification.success({
        message: "Success!",
        description: "Publication updated",
      });
    });
  };

  const favoriteChanged = (a) => {
    AdminService.changePubsFavorite(a).then((data) => {
      setInitialRender(true);
      notification.success({
        message: "Success!",
        description: "Publication updated",
      });
    });
  };
  const bannerChanged = (a) => {
    AdminService.changePubsBanner(a).then((data) => {
      setInitialRender(true);
      notification.success({
        message: "Success!",
        description: "Publication updated",
      });
    });
  };
  const goDetails = (veri) => {
    router.push(`/home/publications/${veri.key}/details`, { veri });
  };
  const deletePubs = (id) => {
    AdminService.deletePubs(id).then((data) => {
      notification.success({
        message: "Success!",
        description: "Your Publication has been deleted successfully",
      });
      setInitialRender(true);
    });
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "p",
      render: (p) => p.name,
    },
    {
      title: "Title",
      dataIndex: "p",
      render: (p) => p.title,
    },

    {
      title: "is Active?",
      dataIndex: "p",
      render: (p) => (
        <Switch checked={p.isActive} onChange={() => activeChanged(p.id)} />
      ),
    },
    {
      title: "is Favorite?",
      dataIndex: "p",
      render: (p) => (
        <Switch checked={p.isFavorite} onChange={() => favoriteChanged(p.id)} />
      ),
    },
    {
      title: "is Banner?",
      dataIndex: "p",
      render: (p) => (
        <Switch checked={p.isBanner} onChange={() => bannerChanged(p.id)} />
      ),
    },
    {
      title: "Actions",
      render: (p) => (
        <Space>
          <Tooltip title="Publication Details" placement="left" key={p.id}>
            <Button
              type="primary"
              onClick={() => goDetails(p)}
              icon={<SearchOutlined />}
            />
          </Tooltip>

          <Tooltip title="Delete Publication" placement="left" key={p.title}>
            <Popconfirm
              title="Are you sure ?"
              onConfirm={() => deletePubs(p.key)}
              okText="Yes! Delete."
              cancelText="Cancel"
            >
              <Button type="primary" danger icon={<DeleteOutlined />} />
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ];
  return (
    <div className="max-w-5xl">
      <PageHeader
        className="site-page-header"
        onBack={() => router.goBack()}
        title={newPanel ? "Add New Publication" : "Publication List"}
        extra={[
          <Button
            key="a"
            onClick={() => setNewPanel(!newPanel)}
            icon={newPanel ? <CloseOutlined /> : <PlusOutlined />}
            type="primary"
            danger={newPanel}
          >
            {newPanel ? "Cancel" : "Add Publication"}
          </Button>,
        ]}
      />
      {!newPanel && (
        <Table
          scroll={{ x: 600 }}
          loading={publications === null}
          dataSource={publications}
          bordered
          columns={columns}
          size="small"
        />
      )}
      {newPanel && (
        <NewPublicationForm sir={setInitialRender} newPanel={setNewPanel} />
      )}
    </div>
  );
};

export default Publication;
