import React from "react";
import FacebookWidget from "./FacebookWidget";
import TwitterWidget from "./TwitterWidget";

const SocialBanner = () => {
  return (
    <div className=" bg-gradient-to-b from-white to-gray-100">
      <div className="max-w-5xl mx-auto">
        <div className="relative pt-16 pb-20 px-4 sm:px-6 lg:pt-10 lg:pb-16 lg:px-8">
          <div className="absolute inset-0">
            <div className="h-1/3 sm:h-2/3 border-t" />
          </div>
          <div className="relative max-w-7xl mx-auto">
            <div className="text-center">
              <h2 className="text-3xl tracking-tight font-extrabold text-red-600 sm:text-4xl">
                Social Media Posts
              </h2>
              <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
                Latest social media posts about AFD activities and announcements
              </p>
            </div>
            <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-2 lg:max-w-none">
              <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                <div
                  style={{
                    width: "100%",
                    position: "relative",
                    overflow: "hidden",
                  }}
                >
                  <div
                    style={{
                      borderBottom: "1px solid #ddd",
                      padding: "8px 8px 8px 15px",
                      width: "100%",

                      backgroundColor: "white",
                      border: "1px solid #ddd",
                      borderRadius: "8px 8px 0 0 ",
                    }}
                  >
                    <span style={{ fontSize: "18px", lineHeight: "30px" }}>
                      From Facebook
                    </span>
                  </div>
                  <FacebookWidget />
                </div>
              </div>
              <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                <TwitterWidget />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialBanner;
