import { message } from "antd";
import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { useLocation } from "react-router-dom";

import { SizeMe } from "react-sizeme";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js`;
const PublicationView = () => {
  const location = useLocation();
  const [numPages, setNumPages] = useState(null);
  const { url } = location.state || { url: null };
  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
    //  message.destroy();
  }

  useEffect(() => {
    message.loading("Loading...");
  }, []);
  return (
    <SizeMe>
      {({ size }) => (
        <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              width={size.width ? size.width : 1}
            />
          ))}
        </Document>
      )}
    </SizeMe>
  );
};

export default PublicationView;
