/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import {
  CalendarIcon,
  BadgeCheckIcon,
  IdentificationIcon,
  MenuIcon,
  DocumentDownloadIcon,
  SupportIcon,
  NewspaperIcon,
  XIcon,
  ChipIcon,
} from "@heroicons/react/outline";

import { ChevronDownIcon } from "@heroicons/react/solid";
import React from "react";
import { Link } from "react-router-dom";
import MainMenu from "./MainMenu";
import FooterWidget from "../components/home/FooterWidget";
import { AdminService } from "../resources/AdminService";

const solutions = [
  {
    name: "About Us",
    description: "About Advocates for Dignity",
    href: "/home/about",
    icon: IdentificationIcon,
  },
  {
    name: "Our Mission & Vision",
    description: "Rule of law, democracy, human rights and liberties",
    href: "/home/mission",
    icon: BadgeCheckIcon,
  },
  {
    name: "Core Values",

    href: "/home/corevalues",
    icon: ChipIcon,
  },
];
const callsToAction = [
  { name: "Events", href: "/home/events", icon: CalendarIcon },
  {
    name: "Publications",
    href: "/home/publications",
    icon: DocumentDownloadIcon,
  },
];
const resources = [
  {
    name: "Contact",
    description: "Get all of your questions or contact support.",
    href: "/home/contact",
    icon: SupportIcon,
  },
];
const mobileMenu = [
  {
    name: "About Us",
    description: "About Advocates for Dignity",
    href: "/home/about",
    icon: IdentificationIcon,
  },
  {
    name: "Our Mission & Vision",
    description: "Rule of law, democracy, human rights and liberties",
    href: "/home/mission",
    icon: BadgeCheckIcon,
  },
  {
    name: "Core Values",
    href: "/home/corevalues",
    icon: ChipIcon,
  },
  { name: "Events", href: "/home/events", icon: CalendarIcon },
  { name: "News", href: "/home/news", icon: NewspaperIcon },
  {
    name: "Publications",
    href: "/home/publications",
    icon: DocumentDownloadIcon,
  },
  {
    name: "Contact",
    href: "/home/contact",
    icon: SupportIcon,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function HomeLayout() {
  const [initialRender, setInitialRender] = useState(true);
  const [layout, setLayout] = useState(null);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getLayout().then((data) => {
        setLayout(data.data);
      });
    }
  }, [initialRender]);
  return (
    <div
      style={{ backgroundColor: "rgba(216, 202, 186,0.7)" }}
      className="min-h-screen overflow-x-hidden"
    >
      <div className="container mx-auto ">
        <Popover className="relative bg-white">
          {({ open }) => (
            <>
              <div className=" mx-auto px-4 sm:px-6   border-gray-300 shadow-md">
                <div className="flex justify-between items-center  py-6 md:justify-start md:space-x-10">
                  <div className="flex justify-start lg:w-0 lg:flex-1">
                    <Link to="/">
                      <span className="sr-only">Workflow</span>
                      <img
                        className="h-14 w-auto sm:h-14"
                        src={require("../assets/images/logo.png").default}
                        alt=""
                      />
                    </Link>
                  </div>
                  <div className="-mr-2 -my-2 md:hidden">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-red-500">
                      <span className="sr-only">Open menu</span>
                      <MenuIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                  <Popover.Group as="nav" className="hidden md:flex space-x-5">
                    <Link
                      to="/home"
                      className="text-base font-medium text-gray-700 hover:text-gray-900"
                    >
                      HOME
                    </Link>
                    <Popover className="relative">
                      {({ open }) => (
                        <>
                          <Popover.Button
                            className={classNames(
                              open ? "text-gray-900" : "text-gray-700",
                              "group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none "
                            )}
                          >
                            ABOUT
                            <ChevronDownIcon
                              className={classNames(
                                open ? "text-gray-600" : "text-gray-500",
                                "ml-0.5 h-5 w-5 group-hover:text-gray-500"
                              )}
                              aria-hidden="true"
                            />
                          </Popover.Button>

                          <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                          >
                            <Popover.Panel
                              static
                              className="absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                            >
                              {({ close }) => (
                                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                  <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 ">
                                    {solutions.map((item) => (
                                      <Link
                                        onClick={() => close()}
                                        key={item.name}
                                        to={item.href}
                                        className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-100"
                                      >
                                        <item.icon
                                          className="flex-shrink-0 h-6 w-6 text-red-500"
                                          aria-hidden="true"
                                        />
                                        <div className="ml-4">
                                          <p className="text-base font-medium text-gray-900">
                                            {item.name}
                                          </p>
                                          <p className="mt-1 text-sm text-gray-500">
                                            {item.description}
                                          </p>
                                        </div>
                                      </Link>
                                    ))}
                                  </div>
                                  <div className="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                                    {callsToAction.map((item) => (
                                      <div
                                        key={item.name}
                                        className="flow-root"
                                      >
                                        <Link
                                          onClick={() => close()}
                                          to={item.href}
                                          className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100"
                                        >
                                          <item.icon
                                            className="flex-shrink-0 h-6 w-6 text-gray-400"
                                            aria-hidden="true"
                                          />
                                          <span className="ml-3">
                                            {item.name}
                                          </span>
                                        </Link>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              )}
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>

                    <Link
                      to="/home/events"
                      className="text-base font-medium text-gray-700 hover:text-gray-900"
                    >
                      EVENTS
                    </Link>

                    <Link
                      to="/home/news"
                      className="text-base font-medium text-gray-700 hover:text-gray-900"
                    >
                      NEWS
                    </Link>
                    <Link
                      to="/home/publications"
                      className="text-base font-medium text-gray-700 hover:text-gray-900"
                    >
                      PUBLICATIONS
                    </Link>
                    <Popover className="relative">
                      {({ open }) => (
                        <>
                          <Popover.Button
                            className={classNames(
                              open ? "text-gray-900" : "text-gray-700",
                              "group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none "
                            )}
                          >
                            <span>MORE</span>
                            <ChevronDownIcon
                              className={classNames(
                                open ? "text-gray-600" : "text-gray-400",
                                "ml-0.5 h-5 w-5 group-hover:text-gray-500"
                              )}
                              aria-hidden="true"
                            />
                          </Popover.Button>

                          <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                          >
                            <Popover.Panel
                              static
                              className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0"
                            >
                              {({ close }) => (
                                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                  <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                    {resources.map((item) => (
                                      <Link
                                        onClick={() => close()}
                                        key={item.name}
                                        to={item.href}
                                        className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                                      >
                                        <item.icon
                                          className="flex-shrink-0 h-6 w-6 text-red-600"
                                          aria-hidden="true"
                                        />
                                        <div className="ml-4">
                                          <p className="text-base font-medium text-gray-900">
                                            {item.name}
                                          </p>
                                          <p className="mt-1 text-sm text-gray-500">
                                            {item.description}
                                          </p>
                                        </div>
                                      </Link>
                                    ))}
                                  </div>
                                  <div className="px-5 py-5 bg-gray-50 sm:px-8 sm:py-8">
                                    <div>
                                      <h3 className="text-sm tracking-wide font-medium text-gray-500 uppercase">
                                        Recent Posts
                                      </h3>
                                      <ul className="mt-4 space-y-4">
                                        {layout.news.map((post) => (
                                          <li
                                            key={post.id}
                                            className="text-base truncate"
                                          >
                                            <Link
                                              onClick={() => close()}
                                              to={`/home/news/${post.id}/details`}
                                              className="font-medium text-gray-900 hover:text-gray-700"
                                            >
                                              {post.title}
                                            </Link>
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                    <div className="mt-5 text-sm">
                                      <Link
                                        onClick={() => close()}
                                        to="/home/news"
                                        className="font-medium text-red-600 hover:text-red-500"
                                      >
                                        View all posts
                                        <span aria-hidden="true">&rarr;</span>
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>
                  </Popover.Group>
                  <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                    <Link
                      to="/login"
                      className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-red-600 hover:bg-red-700"
                    >
                      Login
                    </Link>
                  </div>
                </div>
              </div>

              <Transition
                show={open}
                as={Fragment}
                enter="duration-200 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Popover.Panel
                  focus
                  static
                  className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-10"
                >
                  {({ close }) => (
                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                      <div className="pt-5 pb-6 px-5">
                        <div className="flex items-center justify-between">
                          <div>
                            <img
                              className="h-12 w-auto"
                              src={require("../assets/images/logo.png").default}
                              alt="logo"
                            />
                          </div>
                          <div className="-mr-2">
                            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-red-500">
                              <span className="sr-only">Close menu</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </Popover.Button>
                          </div>
                        </div>
                      </div>
                      <div className="py-6 px-5 space-y-6">
                        <div className="grid grid-cols-1 gap-y-6 gap-x-8">
                          {mobileMenu.map((item) => (
                            <Link
                              onClick={() => close()}
                              key={item.name}
                              to={item.href}
                              className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                            >
                              <item.icon
                                className="flex-shrink-0 h-6 w-6 text-red-600"
                                aria-hidden="true"
                              />
                              <span className="ml-3 text-base font-medium text-gray-900">
                                {item.name}
                              </span>
                            </Link>
                          ))}
                        </div>
                      </div>
                      <div className="px-5 py-5 bg-gray-50 sm:px-8 sm:py-8">
                        <div>
                          <h3 className="text-sm tracking-wide font-medium text-gray-500 uppercase">
                            Recent Posts
                          </h3>
                          <ul className="mt-4 space-y-4">
                            {layout.news.map((post) => (
                              <li key={post.id} className="text-base truncate">
                                <Link
                                  onClick={() => close()}
                                  to={`/home/news/${post.id}/details`}
                                  className="font-medium text-gray-900 hover:text-gray-700"
                                >
                                  {post.title}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div className="mt-5 text-sm">
                          <Link
                            onClick={() => close()}
                            to="/home/news"
                            className="font-medium text-red-600 hover:text-red-500"
                          >
                            View all posts
                            <span aria-hidden="true">&rarr;</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  )}
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>

        <MainMenu sic={null} layout={layout} />
        <FooterWidget />
        <br />
      </div>
    </div>
  );
}
