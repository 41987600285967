import { ArrowRightOutlined, CaretRightOutlined } from "@ant-design/icons";
import React from "react";

const CoreValues = () => {
  return (
    <div className="text-base font-semibold text-gray-600 leading-10 p-2 lg:p-6 mx-auto max-w-4xl ">
      <img
        src={require("../../assets/images/banner.jpg").default}
        alt="banner"
        className="w-full mb-6 -mt-6"
      />
      <h2 className=" text-red-600 font-semibold tracking-wide uppercase text-2xl">
        CORE VALUES
      </h2>
      <ArrowRightOutlined className="mr-2" /> Human Rights and liberty
      <br /> <CaretRightOutlined className="ml-6" />
      <span className="font-normal ml-2">
        To uphold the rule of law, democracy, human rights and liberty
      </span>
      <br />
      <ArrowRightOutlined className="mr-2" />
      Accountability and justice
      <br /> <ArrowRightOutlined className="mr-2" />
      Respect and empathy
      <br /> <ArrowRightOutlined className="mr-2" />
      Safety and security
      <br /> <CaretRightOutlined className="ml-6" />
      <span className="font-normal ml-2">
        Promote safety, security and prosperity for individuals no matter what
        their colour, creed or religion.
      </span>
      <br /> <CaretRightOutlined className="ml-6" />
      <span className="font-normal ml-2">
        To openly support and raise awareness against human rights violations.
      </span>
      <br /> <CaretRightOutlined className="ml-6" />
      <span className="font-normal ml-2">
        To be the voice for the voiceless by creating platforms that give
        individuals the opportunity to be heard.
      </span>
      <br /> <ArrowRightOutlined className="mr-2" />
      Peace and non-violence
      <br /> <ArrowRightOutlined className="mr-2" />
      Engagement and collaboration
      <br /> <ArrowRightOutlined className="mr-2" />
      Rigour and responsiveness
      <br /> <ArrowRightOutlined className="mr-2" />
      Advocacy and education
      <br />
      <br />
      <br />
    </div>
  );
};

export default CoreValues;
