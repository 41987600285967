import React from "react";
import { CheckIcon } from "@heroicons/react/outline";

const Mission = () => {
  const missions = [
    {
      title:
        "Document cases of torture and ill treatment by authorities in detention centres, prisons, or other holding areas designated for suspects and inmates. Report suspicious deaths in police custody or in jails and whether an effective enquiry is carried out to find out what happened in each case.",
    },
    {
      title:
        "Follow and report cases in which relatives and family members of those detained and arrested are subjected to abuse, torture, and harassment.",
    },
    {
      title:
        "Document violations of the freedom of the press and the freedom of expression, including but not limited to keeping track of journalists who are detained, arrested, jailed, prosecuted, and convicted on trumped up charges fabricated by the authorities.",
    },
    {
      title:
        "Monitor the shift of the media spectrum in Turkey, track changes in media outlets, ownership structures, identify propaganda machines run by the government, and expose the army of internet trolls that is covertly funded by the government to smear critics and opponents.",
    },
    {
      title:
        "Document cases of violations of the right to free enterprise, illegal seizures of assets, and unlawful takeover of companies, and identify cases of arbitrary prosecution and jailing of businesspeople who are not aligned with the governing party’s ideology.",
    },
    {
      title:
        "Oversee mass purges that have been undertaken by the government to create a partisan bureaucracy or classification in the civil service that is flourishing on corruption, nepotism, and lack of transparency and accountability in governance.",
    },
    {
      title:
        "Scrutinize the judiciary, monitor the regress in the rule of law, question impunity in government officials, and track cases of mass purges and/or arrests of judges and prosecutors who have rendered judgments not to the liking and satisfaction of the government.",
    },
    {
      title:
        "Review Turkey’s performance on the fulfillment of the commitments it has made to regional and international law and rights organizations to which Turkey has consented by ratifying conventions and treaties.",
    },
    {
      title:
        "Inspect and document hate speech and hate crime cases, especially those committed by senior officials, against vulnerable groups including but not limited to members of the Hizmet Movement, foreign nationals, non-Muslims, Alevis, Kurds, Assyrians and other minority groups.",
    },
    {
      title:
        "Monitor extremism and radical movements, especially those based on religious militancy in the predominantly Sunni nation of Turkey. Track hate-mongering clerics and organizers and facilitators of radical Islamist ideologies in Turkish society.",
    },
    {
      title:
        "Keep track of the Turkish government’s covert operations abroad, decode the parallel networks set up with slush funds to export the ongoing witch hunt in Turkey to other countries. Uncover illegal profiling and harassment of critics and dissidents among expatriate communities, and reveal unlawful intelligence operations that violate the rights of Turkish citizens abroad.",
    },
    {
      title:
        "Document cases of violence against women, underage marriages, and sexual abuse of minors, and assess the progress on women’s empowerment, gender equality, and women’s participation in the labour force, and review legislative and judicial procedures to identify shortcomings in the system that hinder addressing problems.",
    },
  ];
  return (
    <div className="bg-white overflow-hidden pb-56">
      <div className="relative max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
        <div className="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen" />
        <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
          <div>
            <h2 className="text-base text-red-600 font-semibold tracking-wide uppercase">
              MISSION & VISION
            </h2>
          </div>
        </div>
        <div className="mt-4 lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative lg:row-start-1 lg:col-start-2">
            <svg
              className="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"
              />
            </svg>
            <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
              <figure>
                <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                  <img
                    className=" object-cover object-center"
                    src={require("../../assets/images/logo.png").default}
                    alt="Logo"
                  />
                </div>
              </figure>
            </div>
          </div>
          <div className="mt-4 lg:mt-0">
            <h3 className="mt-2 text-xl leading-8 font-extrabold tracking-tight text-gray-800 sm:text-xl">
              Our mission
            </h3>
            <br />
            <div className="booker text-base text-gray-600 -mt-4">
              TO ADVOCATE FOR HUMAN RIGHTS IN AUSTRALIA AND THE WORLD, BY
              ENGAGING COMMUNITIES AND ORGANISATIONS , LAW AND POLICY MAKERS.
            </div>
            <br />
            <h3 className="mt-4 text-xl leading-8 font-extrabold tracking-tight text-gray-800 sm:text-xl">
              Our vision
            </h3>
            <br />
            <div className="booker text-base text-gray-600 -mt-4">
              A WORLD IN WHICH EVERYPERSON IS TREATED WITH DIGNITY, RESPECT, AND
              EQUITY
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mission;
